import { Container, Grid, Paper, Typography } from '@mui/material';
import BannerColumn from './BannerColumn';
import BlogGrid from './BlogGrid';
import { BlogFilter, BlogStatus } from '../model/blog';
import { getCategoryId } from '../common/BlogParser';
import SyndicateGrid from './SyndicateGrid';

export default function BlogPage({ blogStatus, filter }: { blogStatus: BlogStatus, filter: BlogFilter }) {

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent="center" id="coupons" direction="row">

        <Grid item xs={12} sm={9} sx={{ marginTop: '25px' }}>
          <Grid container spacing={3} id="coupons" direction="column">

            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
              <BlogGrid numPosts={6} paginationEnabled={true} blogStatus={blogStatus} decided={true} filter={filter} categoryIds={getCategoryId(filter)} />
            </Paper>


            {(filter === BlogFilter.POOL || filter === BlogFilter.TRAV) &&
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Andelsspel med andelar kvar</Typography>
                <SyndicateGrid numPosts={20} filter={filter}/>
              </Paper>
            }

          </Grid>
        </Grid>

        <Grid item xs={3}>
          <BannerColumn />
        </Grid>
      </Grid>
    </Container>
  );
}



