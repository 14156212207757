import { Grid, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBlogCategories, getBlogPosts } from '../common/BlogApi';
import { BlogCategoriesResponse, BlogFilter, BlogResponse, BlogStatus } from '../model/blog';
import BlogItem from './BlogItem';
import { forEach } from 'lodash';
import React from 'react';
import { getCategories } from '../common/BlogParser';

export default function BlogGrid({ numPosts, paginationEnabled, blogStatus, decided, filter,categoryIds }:
  { numPosts: number, paginationEnabled: boolean, blogStatus: BlogStatus, decided: boolean, filter: BlogFilter, categoryIds: number[] }) {

  let emptyBlogPosts: BlogResponse = { data: { posts: [], pagination: { total: 0, per_page: 0, current_page: 0, last_page: 0 } } };
  let emptyBlogCategories: BlogCategoriesResponse = { data: { categories: [] } };

  let [blogCategories, setBlogCategories] = useState(emptyBlogCategories);
  let [blogPosts, setBlogPosts] = useState(emptyBlogPosts);
  let [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = React.useState(1);

  let numPostsToQuery = numPosts;
  if (!decided) {
    numPostsToQuery = numPosts * 3;
  }

  useEffect(() => {

    console.log('trygetBlogPosts');

    setLoading(true);
    getBlogCategories(setBlogCategories);
    getBlogPosts(setBlogPosts, numPostsToQuery, page, blogStatus,categoryIds);
  }, [loading, page, numPostsToQuery, blogStatus, categoryIds]);

  if (!blogPosts.data.posts || blogPosts.data.posts.length === 0) {
    return <></>;
  }

  const allowedCategories = getCategories(filter);

  let postsToShow = blogPosts.data.posts.filter(p => decided || !p.categories.find(c => c.title === "Avgjort"));
  if (filter !== BlogFilter.ALL) {
    postsToShow = postsToShow.filter(p => p.categories.find(c => allowedCategories.find(a => a === c.title)))
  }

  postsToShow = postsToShow.slice(0, numPosts);

  forEach(postsToShow, function (blogPost) {
    if (blogPost && blogPost.categories && blogPost.categories.length > 0) {
      let categorieId = blogPost.categories.find(c => c.title !== "Avgjort");
      if (categorieId) {
        let categorie = blogCategories.data.categories.find((c) => c.id === categorieId.id);
        if (categorie) {
          blogPost.categoryImage = categorie.background_image;
        }
      }
    }
  });

  return (
    <Grid container spacing={2} sx={{ justifyContent: "center", padding: "20px" }}>
      {postsToShow.map((blogPost, index) =>
        <Grid item key={"displayGrid" + index} sx={{ minWidth: '320px', maxWidth: '320px' }} >
          <BlogItem blogPost={blogPost} index={index} key={"display" + index} />
        </Grid>)
      }
      {paginationEnabled &&
        <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
          <Pagination count={blogPosts.data.pagination.last_page} defaultPage={blogPosts.data.pagination.current_page} onChange={(e, page) => setPage(page)} />
        </Grid>
      }
    </Grid>
  );
}



